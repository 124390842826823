import "./style.scss";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Iframe from "react-iframe";
import {
  updateHeaderStatus,
  updateFooterStatus,
  updateConfigHeaderFooter,
  updateConfigCampaign,
} from "../../../actions";
import {
  getToken,
  learningContentsTracking,
  learningContentUpdate,
  recordTracking,
} from "../../../services/api";
import Rate from "../../../components/Rate";
import { useHistory } from "react-router-dom";
import Preloader from "../../atoms/Preloader";

const token = getToken();

function ContentSite(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { data, dataParentObj, idSection } = props;

  const _recordTracking = async (_type: string | null = null) => {
    if (props.data.idContentEnrollment) return false;

    // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
    const timeStart = 0;
    const navItem = props.data.idContentItem;
    const navType = 1;
    const idSection = props.data.idSection;
    const timeExecuted = 0;

    // console.log(timeStart, navItem, navType, idSection, timeExecuted);
    const res = await recordTracking(
      timeStart,
      navItem,
      navType,
      idSection,
      timeExecuted
    );

    const earnPointsStatus = res.data.earnPoints && props.data.completed === 0;
    //const earnPointsStatus = true;

    props.updateConfigCampaign({
      earnPointsStatus: _type === "WillUnmount" ? false : earnPointsStatus,
    });
  };

  useEffect(() => {
    const { updateConfigHeaderFooter } = props;

    updateConfigHeaderFooter({
      logoStyle: data.text ? `hide` : "show",
      titleHeader: data.text ? `${data.text}` : null,
      prevNavStyle: "closeShow",
      footerStyle: "hide",
      contentNavigation: dataParentObj,
      backCloseText: "FECHAR CONTEÚDO",
    });

    if (!Boolean(data.contentLocation) && !Boolean(data.content)) {
      history.goBack();
    }

    return () => {
      props.updateConfigCampaign({ earnPointsStatus: false });
    };
  });

  const enrollmentTracking = async () => {
    if (
      props.data.idContentEnrollment &&
      props.dataParentObj.contentEnrollment.status != "COMPLETED"
    ) {
      if (props.data.idContentEnrollment) {
        const objTrackingContent = {
          status: "COMPLETED",
          grade: 0,
          progress: 100,
        };

        await learningContentUpdate(
          objTrackingContent,
          props.data.idContentEnrollment
        );
      }

      if (props.data.idEnrollment) {
        const objTrackingContent = {
          idEnrollment: props.data.idEnrollment,
          type: "ACCESS_CONTENT",
          progress: 100,
        };

        await learningContentsTracking(
          objTrackingContent,
          props.data.idContentItem
        );
      }

      props.updateConfigHeaderFooter({
        logoStyle: data.text ? `hide` : "show",
        titleHeader: data.text ? `${data.text}` : null,
        prevNavStyle: "closeShow",
        footerStyle: "hide",
        contentNavigation: dataParentObj,
        backCloseText: "FECHAR CONTEÚDO",
      });
    }
  };

  return (
    <>
      {loading ? <Preloader /> : null}
      {/* {
        setTimeout(() => {
          setLoading(false);
        }, 3000)
      } */}
      <div className="pctPage ptcContentSite">
        <Iframe
          url={`${data.contentLocation}?token=${token}`}
          height="100%"
          width="100%"
          onLoad={() => {
            setLoading(false);
            _recordTracking();
            enrollmentTracking();
          }}
          allowFullScreen
        />
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
  updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
});

export default connect(null, mapDispatchToProps)(ContentSite);
